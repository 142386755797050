import React, { useState, useEffect } from "react";
import { useNavigate } from "../hooks";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import { useResetPassword } from "../mutations";
import produce from "immer";
import _ from "lodash";
import { ProtectComponentAfterSingin } from "../components/ProtectComponents";
import queryString from "query-string";
import { useTrans } from "../hooks";

// ====================================================

const Register = ({ pageContext, location }) => {
  const { mainPhoto, seoData, layoutProps } = pageContext;
  const transMsg = useTrans();
  const navigate = useNavigate();
  const [enableSection] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    password: "",
    passwordConfirmation: "",
  });

  useEffect(() => {
    if (_.isEmpty(location.search)) return;
    const params = queryString.parse(location.search);
    const { code } = params;
    if (_.isEmpty(code)) return;

    setFormData((f) => {
      return { ...f, code };
    });
  }, [location]);

  const { data, error, setData: setVariable } = useResetPassword();

  const passwordMatch = () => {
    return formData.passwordConfirmation === formData.password;
  };

  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setFormData(
      produce(formData, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      }),
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setVariable({
      variables: {
        ...formData,
      },
    });
  };

  useEffect(() => {
    if (_.isEmpty(data)) return;

    navigate("/login");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ProtectComponentAfterSingin location={location}>
      <Layout seo={seoData} {...layoutProps}>
        <Container fluid className="bg-white">
          <Row>
            <Col
              md={4}
              lg={6}
              className="d-none d-md-flex bg-image"
              style={{
                backgroundImage: `url(${mainPhoto?.url})`,
              }}
            ></Col>
            <Col md={8} lg={6}>
              <div className="login d-flex align-items-center py-5">
                <Container>
                  <Row>
                    <Col
                      md={9}
                      lg={8}
                      className="mx-auto pl-5 pr-5"
                      hidden={enableSection === true}
                    >
                      <div> {error && JSON.stringify(error)}</div>
                      <h3 className="login-heading mb-1">
                        {transMsg("resetYourPassword")}
                      </h3>
                      <p className="login-heading mb-4">
                        {transMsg("confirmNewPasswordMessage")}
                      </p>
                      <Form onSubmit={onSubmit}>
                        <div className="form-label-group">
                          <Form.Control
                            type="password"
                            id="inputPassword"
                            placeholder={transMsg("password")}
                            name="password"
                            required
                            onChange={onInputChange}
                          />
                          <Form.Label htmlFor="inputPassword">
                            {transMsg("password")}
                          </Form.Label>
                        </div>
                        <div className="form-label-group mb-4">
                          <Form.Control
                            type="password"
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            placeholder={transMsg("confirmPassword")}
                            required
                            onChange={onInputChange}
                            isInvalid={!passwordMatch()}
                          />
                          <Form.Label htmlFor="passwordConfirmation">
                            {transMsg("confirmPassword")}
                          </Form.Label>
                        </div>

                        <Button
                          className="btn btn-lg btn-outline-primaryOFF btn-block btn-login text-uppercase font-weight-bold mb-2"
                          type="submit"
                          // onClick={() => {
                          //   setEnableSection(true);
                          // }}
                        >
                          {transMsg("resetPassword")}
                        </Button>
                      </Form>
                    </Col>
                    {/* after continue */}
                    <Col
                      md={9}
                      lg={8}
                      className="mx-auto pl-5 pr-5"
                      hidden={enableSection !== true}
                    >
                      <div> {error && JSON.stringify(error)}</div>
                      <h3 className="login-heading mb-1"> {transMsg("resetEmailSent")}</h3>
                      <p className="login-heading mb-4">
                        {transMsg("sentEmailPasswordMessage")}
                      </p>
                      <Form onSubmit={onSubmit}>
                        <Button
                          className="btn btn-lg btn-outline-primaryOFF btn-block btn-login text-uppercase font-weight-bold mb-2"
                          type="submit"
                        >
                          {transMsg("sendAgain")}
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </ProtectComponentAfterSingin>
  );
};

export default Register;
